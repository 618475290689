/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import {ImageSharpFluid} from '../graphqlTypes'
import arrowRight from 'images/arrow-right.svg'
import {useTranslation} from 'react-i18next'

type ProjectCardProps = {
  className?: string
  title: string
  description: string
  slug: string
  imgObj: {
    childImageSharp: {
      fluid: Pick<
        ImageSharpFluid,
        'sizes' | 'base64' | 'aspectRatio' | 'src' | 'srcSet'
      >
    }
  }
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  className,
  title,
  slug,
  imgObj,
  description,
}) => {
  const fluidImage = imgObj?.childImageSharp?.fluid
  const {t} = useTranslation()
  if (!fluidImage) {
    throw new Error(`We didn't find an image whose slug is ${slug}`)
  }
  return (
    <Link
      key={title}
      className={className}
      to={`/${slug}/`}
      sx={{
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'black',
      }}
    >
      <div
        sx={{
          mt: [40, 40, 0],
          width: 316,
          height: 400,
          bg: 'white',
          borderRadius: 16,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0px 12px 26px #F2F2F2',
        }}
      >
        <Img fluid={fluidImage} />
        <div
          sx={{
            flex: 1,
            pt: 33,
            pb: 25,
            px: 28,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3
              sx={{
                my: 0,
                fontSize: 18,
              }}
            >
              {title}
            </h3>
            <p
              sx={{
                mt: 7,
                mb: 0,
                fontSize: 14,
                color: 'soft',
              }}
            >
              {description}
            </p>
          </div>
          <span
            sx={{
              display: 'inline-block',

              color: 'primary',
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {t('Learn More')}
            <img src={arrowRight} alt="" sx={{ml: 9}} />
          </span>
        </div>
      </div>
    </Link>
  )
}

export default ProjectCard
